@tailwind base;
@tailwind components;
@tailwind utilities;

:focus-visible {
	outline: 4px solid rgba(97, 17, 199, 0.1);
}


@layer base {
	@font-face {
		font-family: 'Gazpacho';
		src: url(./assets/fonts/Gazpacho-Medium.woff2) format('woff2'),
		  	url(./assets/fonts/Gazpacho-Medium.woff) format('woff');
	}
  }

input[type='date']::-webkit-calendar-picker-indicator {
	opacity: 1;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="rgb(139,139,143)" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

details > summary.list-none::-webkit-details-marker,
details > summary.list-none::marker {
	display: none;
}

.docs [id]::before {
	content: '';
	display: block;
	margin-top: -16px;
	height: 16px;
	visibility: hidden;
}

.checkbox-grid-1 {
	grid-template-columns: repeat(1, max-content);
}
.checkbox-grid-2 {
	grid-template-columns: repeat(2, max-content);
}
.checkbox-grid-3 {
	grid-template-columns: repeat(3, max-content);
}
.checkbox-grid-4 {
	grid-template-columns: repeat(4, max-content);
}
.checkbox-grid-5 {
	grid-template-columns: repeat(5, max-content);
}
.checkbox-grid-6 {
	grid-template-columns: repeat(6, max-content);
}
.checkbox-grid-7 {
	grid-template-columns: repeat(7, max-content);
}
.checkbox-grid-8 {
	grid-template-columns: repeat(8, max-content);
}
.checkbox-grid-9 {
	grid-template-columns: repeat(9, max-content);
}
.checkbox-grid-10 {
	grid-template-columns: repeat(10, max-content);
}
